import './App.css';
import CustomRouter from './router/CustomRouter';

function App() {
  return (
    <>
      <CustomRouter />
    </>
  );
}

export default App;
