import React, { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

const Home = React.lazy(() => import("../pages/home/home"));
const About = React.lazy(() => import("../pages/aboutus/aboutus"));
const ContactUs = React.lazy(() => import("../pages/contactus/contactus"));
const Work = React.lazy(() => import("../pages/work/work"));

const CustomRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<Suspense fallback={<></>}><Home /></Suspense>} />
            <Route path='/about' element={<Suspense fallback={<></>}><About /></Suspense>} />
            <Route path='/contact' element={<Suspense fallback={<></>}><ContactUs /></Suspense>} />
            <Route path='/work' element={<Suspense fallback={<></>}><Work /></Suspense>} />
        </Routes>
    )
}

export default React.memo(CustomRouter);